@import "./variables2.scss";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");

.text-underline {
  text-decoration: underline;
}

// Import Bootstrap and its default variables
// @import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
//@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";
@import "~react-datepicker/src/stylesheets/datepicker.scss";

@each $breakpoint, $width in $grid-breakpoints {
  @each $size, $length in $sizes {
    .w-#{$breakpoint}-#{$size} {
      @include media-breakpoint-up($breakpoint) {
        width: $length !important;
      }
    }
  }
}

body {
  border-color: #ffffff;
}

/** Jumbotron above each section. */
.jumbotron {
  border-radius: 0;
  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
    h1 {
      text-transform: uppercase;
      font-size: 24px;
    }
    p {
      font-size: 16px;
    }
    .btn-lg {
      font-size: 20px;
      padding-left: 20px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

/* Feather icons. */
.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

#navbarSupportedContent {
  @include media-breakpoint-down(md) {
    z-index: 1041;
    padding: 120px 15px;
    overflow: hidden;
  }
  touch-action: none;
}
.navbar {
  height: 70px;
  .navbar-toggler {
    color: $primary;
  }
  .nav-link,
  .dropdown-item {
    font-weight: 300 !important;
    letter-spacing: 0.5px;
    margin-right: 20px;
  }
  .navbar-brand {
    @media (max-width: 640px) {
      padding-left: 15px;
    }
  }
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: (64px + 40px) 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  width: 250px;
}

.sidebar + .content {
  margin-left: 250px;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 66px - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500 !important;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
 * Content
 */
.modal {
  z-index: 4001;
}
.modal-backdrop {
  z-index: 3000;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.editor-toolbar.fullscreen {
  z-index: 1050 !important;
}
.CodeMirror.CodeMirror-fullscreen {
  z-index: 1050 !important;
}
.editor-preview-side {
  z-index: 1050 !important;
}

/*
.page-preview:hover,
.page-preview:active {
  .d-none {
    display: block !important;
  }
}
*/

.CodeMirror.CodeMirror-fullscreen {
  height: 100vh !important;
}

/* --- from dashboard */

.project-list {
  @extend .container;
  background-color: $white;
  margin-top: 50px;
  padding: 15px;
  @include media-breakpoint-down(xs) {
    margin: 0;
    padding: 0;
  }
}

.project-card {
  h5 {
    font-size: 16px;
    line-height: 26px;
    margin-top: 8px;
    color: $dark;
  }
  .date {
    font-size: 14px;
    color: $dark;
  }
  .icon {
    border-radius: 10px;
  }
  a:hover {
    text-decoration: none;
  }
  .btn.btn-delete {
    &:hover {
      color: $primary !important;
    }
  }
  overflow: hidden;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: rgba(0, 0, 0, 0.01);
  }
  &.error {
    h5 {
      color: $danger !important;
    }
    .icon {
      background-color: $danger !important;
      background-image: none !important;
    }
    svg text {
      fill: $danger;
    }
  }
}

iframe html {
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

.cursor-pointer {
  cursor: pointer;
}

// custom design v2
//

h1,
.h1,
.rr-h1 {
  /* RR_H1 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 48px !important;
  line-height: 48px !important;
  color: $rr-dark-grey;
}

h2,
.h2,
.rr-h2 {
  /* RR_H2 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  color: $rr-dark-grey;
}
h2.bold,
.h2.bold,
.rr-h2b {
  /* RR_H2B */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  color: $rr-dark-grey;
}

.rr-t1 {
  /* RR_T1 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 26px !important;
  color: $rr-dark-grey;
}

.lead,
.rr-t2 {
  /* RR_T2 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: $rr-dark-grey;
}

h2,
.rr-t2b {
  /* RR_T2B */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: $rr-dark-grey;
}

.rr-t3 {
  /* RR_T3 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: $rr-dark-grey;
}

h3,
.rr-t3b {
  /* RR_T3B */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: $rr-dark-grey;
}
.bolder {
  font-weight: 700 !important;
}

label,
.rr-t4 {
  /* RR_T4 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 16px !important;
}

.small,
.rr-t5 {
  /* RR_T5 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
}

// call to action default styling
.rr-cta {
  /* RR_CTA */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

// storybook, fix border
body,
#root {
  --btn-background-transparent: #ffffff;
}

// buttons

.btn {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  min-height: 40px;
  border-radius: 6px;
  border-width: 0px;
  border-style: solid;
  // box-shadow: 0px 0px 0px 4px transparent;
  border-color: transparent;
  @extend .rr-cta;
  svg {
    height: 16px;
    width: 16px;
  }
}
.btn:hover,
a:hover .btn {
  text-decoration: none !important;
}
.btn.text-center {
  justify-content: center;
}

.btn:disabled {
  opacity: 0.6;
}

.btn.btn-sm {
  height: 32px;
  min-height: 32px !important;
  padding: 6px 16px;
  border-radius: 6px;
  @extend .rr-cta;
  font-size: 14px;
  svg {
    height: 14px;
    width: 14px;
  }
}
.btn-lg {
  height: 64px;
  font-size: 110%;
  padding: 8px 24px 8px 24px;
  gap: 10px;
  svg {
    height: 20px;
    width: 20px;
  }
}

@mixin mkButton($name, $text, $text-active, $background, $border) {
  .btn-#{$name} {
    color: $text;
    background: $background;
    // border-color: var(--btn-background-transparent, #ffffff);
    cursor: pointer;
  }
  .btn-#{$name}:hover:not(:disabled),
  .btn-#{$name}:focus:not(:disabled) {
    color: $text !important;
    background-color: $background;
    box-shadow: 0px 0px 0px 4px $border;
  }
  .btn-#{$name}:active:not(:disabled) {
    color: $text-active !important;
    background-color: $border !important;
    box-shadow: 0px 0px 0px 4px transparent;
    // border-color: $rr-white;
  }

  .btn-outline-#{$name} {
    color: $text-active;
    background-color: $border;
    // border-color: var(--btn-background-transparent, #ffffff);
  }
  .btn-outline-#{$name}:hover:not(:disabled),
  .btn-outline-#{$name}:focus:not(:disabled) {
    color: $text-active;
    background-color: $border;
    // border-color: $border;
    box-shadow: 0px 0px 0px 4px $border;
  }
  .btn-outline-#{$name}:active:not(:disabled) {
    color: $text-active;
    background-color: $border;
    // border-color: $background;
    box-shadow: 0px 0px 0px 4px $background;
  }
}

@include mkButton(
  $name: "primary",
  $text: $rr-white,
  $text-active: $rr-blue,
  $background: $rr-blue,
  $border: $rr-soft-blue
);
@include mkButton(
  $name: "secondary",
  $text: $rr-mid-grey,
  $text-active: $rr-mid-grey,
  $background: $rr-soft-grey,
  $border: $rr-softer-grey
);
@include mkButton(
  $name: "success",
  $text: $rr-white,
  $text-active: $rr-green,
  $background: $rr-green,
  $border: $rr-soft-green
);
@include mkButton(
  $name: "danger",
  $text: $rr-danger,
  $text-active: $rr-danger,
  $background: $rr-soft-danger,
  $border: "transparent"
);

// fields

label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  color: $rr-dark-grey;
}

input,
textarea,
select {
  font-size: 14px;
  line-height: 18px;
  border: 1px solid $rr-soft-grey;
  border-radius: 6px !important;
}
textarea::placeholder,
input::placeholder,
select::placeholder {
  @extend .rr-t3;
  color: $rr-grey !important;
  font-weight: 200 !important;
}

select:hover:not(:disabled),
input:hover:not(:disabled) {
  border: 1px solid $rr-blue !important;
}
select:active:not(:disabled),
select:focus:not(:disabled),
input:active:not(:disabled),
input:focus:not(:disabled) {
  border: 1px solid $rr-blue !important;
  box-shadow: 4px 4px 0px rgba(73, 127, 214, 0.2) !important;
}
input:disabled,
select:disabled {
  opacity: 60%;
}

.is-invalid {
  border: 1px solid $rr-danger !important;
}

.invalid-feedback {
  font-weight: 500 !important;
  font-size: 12px;
  line-height: 14px;
}

.rr-grey {
  color: $rr-grey;
}
.rr-mid-grey {
  color: $rr-mid-grey;
}
.rr-dark-grey {
  color: $rr-dark-grey;
}
.rr-bg-soft-grey {
  background-color: $rr-soft-grey;
}
.hover-rr-bg-softer-grey:hover {
  background-color: $rr-softer-grey;
}
.rr-bg-softer-grey {
  background-color: $rr-softer-grey;
}
.rr-bg-soft-blue {
  background-color: $rr-soft-blue;
}

.alert {
  border-radius: 0px !important;
  .btn {
    margin-top: -4px;
    margin-bottom: -4px;
  }
}
.alert.alert-danger {
  background-color: $rr-soft-danger;
  color: $rr-dark-grey;
  border: 0px solid $rr-soft-danger !important;
  .btn:not(:hover) {
    border-color: inherit;
  }
}
.alert.alert-info {
  background-color: $rr-soft-blue;
  --btn-background-transparent: $rr-soft-blue;
  color: $rr-dark-grey;
}
.alert.alert-success {
  background-color: $rr-soft-green;
  border: 0px solid $rr-soft-green !important;
  color: $rr-dark-grey;
}

.uppercase {
  text-transform: uppercase;
}

// drop down menus

.dropdown-menu.show {
  padding: 12px 8px 12px 8px !important;
  border: 0px solid transparent;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
}

.dropdown-item {
  @extend .rr-t4;
  @extend .rr-dark-grey;
  padding: 8px;
}

.bg-none {
  background: none !important;
}

.dropdown-shadow {
  background-color: rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2000;
}

.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-x-auto {
  overflow-x: auto;
}
.drawer-section {
  p {
    @extend .rr-t3;
    color: $rr-mid-grey;
  }
  .markdown p {
    @extend .rr-t2;
    color: $rr-dark-grey;
    em {
      color: $rr-mid-grey;
    }
    strong {
      font-weight: 600 !important;
    }
  }
}

.valign-middle {
  vertical-align: middle !important;
}

.rounded {
  border-radius: 6px !important;
}

.outline-0 {
  outline: 0 !important;
}

.mde-sm {
  .fa {
    color: $rr-grey;
  }
  .editor-toolbar {
    padding: 2px 10px;
  }
  .editor-toolbar button {
    width: 24px;
  }
  .CodeMirror-line {
    @extend .rr-t2;
    color: $rr-mid-grey !important;
  }
}

.react-datepicker-wrapper {
  display: block;
}
.opacity-20 {
  opacity: 0.2;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-50 {
  opacity: 0.4;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-80 {
  opacity: 0.8;
}

.d-375-block {
  @media (min-width: 375px) {
    display: block !important;
  }
}
.d-375-none {
  @media (min-width: 375px) {
    display: none !important;
  }
}

.nowrap-ellipsis {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.gap-2 {
  gap: 8px;
}
