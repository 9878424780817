// project variables

// $rr-blue: #0088a5;
$rr-blue: #0386a3;
$rr-soft-blue: #eefaff;

$rr-danger: #ff611d;
$rr-soft-danger: #ffd8c8;

$rr-green: #48d597;
$rr-soft-green: #d0ffea;

$rr-dark-grey: #333333;
$rr-mid-grey: #6b6c6f;
$rr-grey: #9ba3b0;
$rr-soft-grey: #e9e9e9;
$rr-softer-grey: #f9f9f9;
$rr-white: #ffffff;

// Override default variables before the import

$primary: $rr-blue;
$accent: $rr-danger;

$soft-blue: $rr-soft-blue;

$text: $rr-dark-grey;
$body: $rr-dark-grey;
$body-bg: $rr-white;

$warning: $rr-soft-danger;
$danger: $rr-danger;
$success: $rr-green;
$soft-green: $rr-soft-green;

// imported from html
// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;500&display=swap");
$font-family-sans-serif: "Poppins", "Arial", "Helvetica", sans-serif;
$font-weight-base: 200;

$border-radius: 6px;
$border-color: $rr-soft-grey;

$alert-border-width: 0px;
